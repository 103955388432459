
export const IMG_CDN="https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_660/";

export const RESTAURANT_API=`https://foodhub-server-jrcf.onrender.com/api/restaurants?lat=17.37240&lng=78.43780&page_type=DESKTOP_WEB_LISTING`;



export const RESTAURANT_MENU_API="https://www.swiggy.com/dapi/menu/pl?page-type=REGULAR_MENU&complete-menu=true&lat=17.37240&lng=78.43780&restaurantId="






